const agreeData = {
  'cn': [
    {
      "topTitle": "《无限觉醒》用户协议",
    },
    {
      'content': [`本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。`]
    },
    {
      'content': [`如果您对本协议或无限觉醒服务有意见或建议，可与无限觉醒客服联系，我们会给予您必要的帮助。`]
    },
    {
      'content': [`无限觉醒采取合理的方式提请用户注意的义务将通过如下方式实现：在本协议中无限觉醒以明确的足以引起用户注意的颜色标记或加粗字体标注等合理方式提醒用户注意相关条款（需要强调的是，还包括用户应特别注意任何未明确标记的含有“不承担”、“免责”“不得”等形式用语的条款），该等条款的确认将导致用户在特定情况下的被动、不便、损失，请用户在确认同意本协议之前再次阅读上述条款。双方确认上述条款非属于《中华人民共和国民法典》第四百九十七条规定的“不合理地免除或者减轻其责任、加重对方责任、限制对方主要权利、排除对方主要权利”的条款。`,
        '如有任何需要说明条款的要求，请立即停止使用服务，同时联系无限觉醒客服，若用户未致电或发送有说明条款要求的邮件至无限觉醒而使用该协议，将被视为同意本协议，则双方在此确认无限觉醒已依法履行了根据用户要求对相关条款进行说明的法定义务，无限觉醒已给予用户充足的时间与充分的选择权来决定是否缔结本协议。',
        '鉴于无限觉醒已依法明确了上述条款、履行了格式条款制订方的义务，用户点击同意或下一步，将被视为且应当被视为用户已经完全注意并同意了本协议所有条款，尤其是提醒用户注意的条款的合法性及有效性，用户不应当以无限觉醒未对格式条款以合理方式提醒用户注意，或未根据用户要求尽到说明义务为理由，而声称或要求法院或其它任何第三方确认相关条款非法或无效。',]
    },
    {
      'content': [`重要须知`]
    },
    {
      'content': [`《无限觉醒通行证用户服务协议》（以下简称“本协议”）是您（或称“用户”或“您”，指注册、登录、使用、浏览无限觉醒服务的个人或组织）与无限觉醒产品、程序及服务（以下简称“服务”）所订立的协议。`,
        '用户在使用无限觉醒提供的无限觉醒产品、程序及服务之前，无限觉醒特别提醒用户认真阅读本协议的全部条款，特别是其中免除或者限制无限觉醒责任的条款（该等条款通常含有“不负任何责任”、“无义务”、“不保证”等词汇）、限制用户权利的条款（该等条款通常含有“不得”、“不应”、“无权”等词汇）、法律适用和争议解决条款，这些条款应在中国法律所允许的范围内最大程度地适用。',
        '请您审慎阅读并选择接受或不接受本协议(未成年人或非为完全民事行为能力人应在监护人陪同下阅读）。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉相关服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。',
        '您对本协议的接受应被视为您接受本协议全部条款的约束，包括接受无限觉醒对任一条款随时所做的任何修改。本协议可由无限觉醒随时更新，更新后的协议条款一旦公布即代替原来的协议条款，您可在无限觉醒网站查阅最新版协议条款。在无限觉醒修改本协议相关条款之后，如果您不接受修改后的条款，请立即停止登录并停止使用无限觉醒提供的服务，继续登录或使用无限觉醒提供的服务将被视为您已接受了修改后的协议。',
        '如果用户未满18周岁的，需在监护人的陪同下阅读本协议，并在取得监护人对用户享用本公司提供的服务、向本公司支付费用的行为，以及对本协议全部条款的同意之后，方可享用本公司提供的服务。'
      ]
    },
    {
      'title': '一、服务内容',
      'content': [
        `1. 无限觉醒通行证服务的具体内容由无限觉醒根据实际情况提供，包括但不限于提供账号登录、账号注册、找回密码、账号锁定、账号信息查询、账号绑定等功能。 无限觉醒保留随时变更、中止或终止部分或全部服务的权利。`,
        `2. 无限觉醒仅提供无限觉醒产品、程序及服务等相关服务，用户应自行配备上网的所需设备，包括但不限于智能设备(包括但不限于手机、平板电脑、电脑)、无线路由器或其他必备上网装置。`,
        '3. 用户自行负担上网所支付的与此服务相关的电信费用、网络使用等费用。',
        `4. 用户应使用正版软件体验无限觉醒服务。`,
        '5. 无限觉醒有权不定时地向用户发送业务公告等信息，通过邮件或短信等方式。',
      ]
    },

    {
      'title': '二、使用规则',
      'content': [
        `1. 用户应当保证注册时提供有效、详尽及准确的个人资料，并不断更新注册资料，符合有效、详尽、准确、完整的要求。提请知悉，所有提供的原始资料将被引用为注册资料。如果因注册信息不真实或更新不及时而引发相关问题，无限觉醒不负任何责任。用户提供的个人资料（包括但不限于账号名称、头像、简介）不得出现违法、不良或不当信息，经无限觉醒审核，如存在上述情况，无限觉醒将不予注册；同时，在注册后，如发现以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法、不良或不当信息的，无限觉醒有权不经通知单方采取限期改正、暂停使用、注销登记、收回账号等措施。`,
        `2. 因您个人原因导致的账号信息遗失，如需找回无限觉醒账号信息，请按照无限觉醒账号找回要求提供相应的信息，并确保您提供的信息合法真实有效，若提供的信息不符合要求，无法通过无限觉醒安全验证及归属验证，无限觉醒有权拒绝提供账号找回服务；若账号的凭证证明不再有效，我们有权拒绝支持账号找回。例如手机号二次出售，无限觉醒可拒绝支持帮助找回原手机号绑定的账号。`,
        `3. 在需要终止使用无限觉醒账号服务时，您可以申请注销您的无限觉醒账号，具体申请流程请向无限觉醒客服联系咨询：`,
        `(1)您仅能申请注销您本人的账号，并依照无限觉醒的流程进行注销；`,
        `(2)您仍应对您在注销账号前且使用无限觉醒服务期间的行为承担相应责任，同时无限觉醒仍可保存您注销前的相关信息；`,
        `(3)注销成功后，账号信息、个人身份证信息、交易记录、账号权益等将被清除且无法恢复或提供。`,
        `4. 无限觉醒有权审查您注册时所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；您有义务妥善保管用户账号及密码，并正确、安全地使用其用户账号及密码。`,
        `5. 您应当同意本协议的条款并按照页面上的提示完成全部的注册程序。在进行注册程序过程中点击“同意”或“下一步”按钮即表示与无限觉醒达成协议，完全接受本协议项下的全部条款。`,
        `6. 用户注册成功后，无限觉醒将授予每个用户一个无限觉醒通行证账号及相应的密码。用户账号和密码由用户负责保管。用户有义务妥善保管其账号及密码，正确、安全地使用其账号及密码，并对登录后所持账号产生的行为依法享有权利和承担责任。在怀疑他人在使用您的账号或密码时，请您立即通知无限觉醒。`,
        `7. 无限觉醒应积极地采取技术与管理等合理措施保障用户账号的安全、有效。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。`,
        `8. 用户承诺以其真实身份信息注册无限觉醒通行证账号，并保证所提供的个人身份资料信息真实、准确、完整、有效，依据法律规定和本协议约定对所提供的信息承担相应的法律责任。用户不得冒充他人，不得利用他人的名义发布任何信息；不得恶意使用注册账号功能导致其他误认；否则无限觉醒有权立即停止提供服务，用户独自承担由此而产生的一切法律责任。`,
        `9. 无限觉醒通行证账号的所有权归无限觉醒所有，基于账号安全性，禁止使用他人的手机号、微信账号等注册无限觉醒通行证账号。完成注册手续后，获得无限觉醒通行证的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。无限觉醒因经营需要，有权收回账号。`,
        `10.无限觉醒提请您知悉，无限觉醒无义务审核是否用户本人使用该组账号及密码，仅审核账号及密码是否与数据库中保存的一致，只要任何人输入的账号及密码与数据库中保存的一致，即可凭借该组账号及密码登录并获得服务，所以账号登录成功情形下，即使用户认为该账号登录行为并非其本人所为，无限觉醒将不承担因此而产生的任何责任。`,
        `11.无限觉醒根据用户的通知采取措施暂停用户账号的登录和使用的，无限觉醒有权要求用户提供并核实与其注册身份信息相一致的个人有效身份信息或证明。无限觉醒核实用户所提供的个人有效身份信息或证明与所注册的身份信息相一致的，应当按照用户请求或视有关情况采取措施暂停用户账号的登录和使用。无限觉醒因根据用户的请求采取相应措施而造成用户或其他用户损失的，由提出该请求的用户自行承担责任。用户没有提供其个人有效身份信息或证明，或者用户提供的个人有效身份信息或证明与所注册的身份信息不一致的，无限觉醒有权拒绝用户上述请求。因此造成用户损失的，由用户自行承担。用户为了维护其合法权益，向无限觉醒提供与所注册的身份信息相一致的个人有效身份信息或证明时，无限觉醒应当依法为用户提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。`,
        `12.用户在使用无限觉醒相关产品、程序和服务过程中，必须同时遵循以下原则：`,
        `(1)遵守中国有关的法律法规；`,
        `(2)遵守所有与服务有关的网络协议、规定和程序；`,
        `(3)发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通知无限觉醒。`,
        `(4)禁止用户在接受服务过程中进行以下侵害行为，包括但不限于：`,
        `(a)违反宪法确定的基本原则的；`,
        `(b)危害国家统一、主权和领土完整的；`,
        `(c)泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；`,
        `(d)煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；`,
        `(e)宣扬邪教、迷信的；`,
        `(f)散布谣言，扰乱社会秩序，破坏社会稳定的；`,
        `(g)宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；`,
        `(h)侮辱、诽谤他人，侵害他人合法权益的；`,
        `(i)违背社会公德的；`,
        `(j)宣扬、教唆使用外挂、私服以及木马的相关内容的；`,
        `(k)发布任何经无限觉醒合理判断为不妥当或者无限觉醒未认可的内容；`,
        `(5)有法律、行政法规和国家规定禁止的其他内容。`,
        `13.《无限觉醒服务协议》以及无限觉醒各个单项服务及/或产品条款和公告可由无限觉醒随时更新。您在使用相关服务及/或产品时, 应关注并遵守社区服务所适用的相关规则和协议的规定(包括但不限于无限觉醒在官方网站、官方微信公众号或无限觉醒内不时发布并修订的协议、用户守则、玩家条例、公告及通知等内容)；遵守中华人民共和国相关法律法规(如果用户是中华人民共和国境外的使用者，还应遵守所属或所在国家或地区的法律法规)。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。`,
        `14.您应避免因使用无限觉醒服务而使无限觉醒及卷入政治和公共事件，否则无限觉醒有权暂停或终止对您的服务。同时无限觉醒有权立即停止对您注册的所有无限觉醒通行证账号提供全部或部分服务，您应当为此独立承担一切责任。`,
        `15.您在使用无限觉醒的服务时，必须遵守《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》、《网络信息内容生态治理规定》等中华人民共和国相关法律法规的规定，用户应同意不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:`,
        '(1)上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：',
        '(a)反对宪法确定的基本原则的;',
        '(b)危害国家统一、主权和领土完整的;',
        '(c)泄露国家秘密、危害国家安全或者损害国家荣誉和利益的;',
        '(d)煽动民族仇恨、民族歧视，破坏民族团结,或者侵害民族风俗、习惯的;',
        '(e)宣扬邪教、迷信的;',
        '(f)扰乱社会秩序，破坏社会稳定的;',
        '(g)宣扬淫秽、赌博、暴力或者教唆犯罪的;',
        '(h)侮辱或者诽谤他人，侵害他人合法权益的;',
        '(i)使用夸张标题，内容与标题严重不符的；',
        '(j)炒作绯闻、丑闻、劣迹等的；',
        '(k)不当评述自然灾害、重大事故等灾难的；',
        '(l)带有性暗示、性挑逗等易使人产生性联想的；',
        '(m)展现血腥、惊悚、残忍等致人身心不适的；',
        '(n)煽动人群歧视、地域歧视等的；',
        '(o)宣扬低俗、庸俗、媚俗内容的；',
        '(p)可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；',
        '(q)其他对网络生态造成不良影响的内容；',
        '(r)危害社会公德或者民族优秀文化传统的;',
        '(s)侵犯他人合法权益的；',
        '(t)含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；',
        '(u)含有法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；',
        '(2)进行以下侵害服务安全性的行为，包括但不限于：',
        '(a)以任何形式违反诚实信用原则，直接或间接采取组织、教唆、窃取、占有、使用、捡取、购买、转卖等手段侵犯任何第三方拥有使用权的账号、角色、虚拟货币、虚拟物品、虚拟道具等权益；',
        '(b)以任何积极或消极的形式侵犯或协助他人侵犯第三方权益的；',

        '(c)进行服务无关的行为，包括但不限于：为任何不当目的、非法目的及与无限觉醒提供服务或产品未有直接关系而使用无限觉醒服务；包括但不限于买卖号、角色、虚拟货币、虚拟道具、虚拟物品，宣传赌博、非法彩票等；',
        '(d)利用无限觉醒提供的相关服务进行任何可能对互联网的正常运转造成不利影响的行为，包括但不限于以任何方式传输含有计算机病毒、破坏性程序的文件或其他任何可能对他人计算机或互联网的正常运转造成不利影响的软件或程序；',
        '(e)利用无限觉醒提供的相关服务进行任何不利于无限觉醒的行为，包括但不限于：进行任何洗钱、预付卡套现、购买和销售或以其他方式非法取得虚拟货币等违法行为；',
        '(3)发布违法、违规或无限觉醒认为的任何不当信息。',
        '16.您根据本协议获得的无限觉醒通行证账号和密码登录无限觉醒产品、程序及服务时，应严格遵守无限觉醒网站或相关服务、产品的相关服务协议及使用守则，包括但不限于《无限觉醒服务协议》。',
      ]
    },


    {
      'title': '三、用户所提供内容的知识产权及授权',
      'content': [
        `1. 无限觉醒尊重知识产权并注重保护用户享有的各项权利。在无限觉醒产品服务中，您可能会通过上传、发布等各种方式向无限觉醒提供原创内容。在此情况下，您仍然享有此等内容的完整知识产权。`,
        `2. 对于用户通过无限觉醒服务（包括但不限于网页、论坛等）上传到无限觉醒网站上可公开获取区域的任何内容，用户同意无限觉醒在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。`,
        `3. 本公司依法享有无限觉醒的一切合法权益（包括但不限于其中涉及的计算机软件著作权、美术作品著作权和专利权等知识产权，运营无限觉醒软件的权利等）。`,
        `4. 无限觉醒所体现的文字、LOGO（图形），以及在具体服务中使用的用以标识服务名称与来源的文字、LOGO（图形）等（包括但不限于应用的名称、LOGO、ICON，软件开发者的名称、LOGO、ICON等）。`,
        `5. 无限觉醒拥有无限觉醒服务中提供的所有资料的知识产权，包括但不限于商标权、版权、专利权。任何被授权的浏览、复制、和传播属于无限觉醒运营的网站内的资料均不得用于商业目的。`,
        `6. 无限觉醒所有的产品、技术与所有程序均属于无限觉醒知识产权，未经无限觉醒事先书面许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、上载、下载）使用，不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。否则，无限觉醒将依法追究法律责任。`,
        `7. 本协议未明确授予您的权利均由本公司保留。`,
      ]
    },
    {
      'title': '四、链接及广告信息',
      'content': [
        `1. 无限觉醒在官方网站、官方微信公众号、产品中所提供的所有链接，可能链接到其他第三方网站，提供该等网站的目的，是便利用户自行搜索或取得相关信息，无限觉醒对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度。这些个人、公司或组织与无限觉醒间不存在任何雇佣、委任、代理、合伙或其他类似的关系。`,
        `2. 无限觉醒在官方网站、官方微信公众号、产品内可能刊登商业广告或其他推广活动信息。这些广告或推广活动信息是由广告商或商业服务提供者提供，并承担相应责任，无限觉醒仅提供刊登内容的媒介。用户通过该等链接的网站或广告所购买的商品或服务，其交易行为仅存在于用户与该商品或服务的提供者之间，与无限觉醒无关，无限觉醒不就用户与该商品或服务的提供者之间所产生的交易行为承担任何法律责任，用户应予以特别留意。`
      ]
    },
    {
      'title': '五、用户信息保护',
      'content': [
        `1. 用户同意：个人隐私信息是指能够对用户进行个人辨识或涉及个人通信的信息，包含但不限于下列信息：用户的姓名，有效身份证件号码，联系方式，家庭地址，IP地址，电子邮件地址等信息。非个人隐私信息是指用户对本软件的操作状态以及使用习惯等一些明确且客观反映在无限觉醒服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息。`,
        `2. 保护用户（特别是未成年人）的隐私是无限觉醒的一项基本政策，未成年人应当在其监护人陪同下阅读，因此，若父母（监护人）希望未成年人（尤其是八岁以下子女）得以使用本服务，必须以父母（监护人）名义申请注册，在接受本服务时，父母（监护人）应判断本服务是否适合于未成年人。`,
        `3. 无限觉醒注重对用户信息资源的保护，会使用各种安全技术和程序来保护用户信息资源不被未经授权的访问、使用和泄漏。除法律或政府要求或用户同意等原因外，无限觉醒未经用户同意不会向除无限觉醒及/或其合作的第三方公开、透露用户信息资源，但因下列原因而披露的除外：`,
        `(1)基于国家法律法规或其他可适用的法律法规的规定而对外披露；`,
        `(2)应有权的司法机关、行政机关及其他主管机关基于法定程序的要求而披露；`,
        '(3)应国家司法机关及其他有关机关基于法定程序的要求而披露；',
        '(4)无限觉醒为了维护自己合法权益而向用户提起诉讼或者仲裁时；',
        '(5)用户本人或用户监护人授权披露的；',
        '(6)为保护无限觉醒或您的合法权益且该措施为适当的披露；',
        '(7)在紧急情况下，为保护其他用户及第三方人身安全而披露；',
        '(8)为维护社会公众的利益。',
        '4. 除前述个人信息资料之外，在不透露单个用户隐私资料的前提下，您同意并授权无限觉醒基于安全、用户体验优化等考虑而收集并使用您的其他必要信息，但无限觉醒仅可为履行本协议之目的收集与使用该等信息。',
        '5. 尽管无限觉醒对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。',
        '6. 在用户参加无限觉醒产品并接受无限觉醒服务的过程中产生并储存于无限觉醒服务器中的任何数据信息，包括但不限于账号数据信息、角色数据信息、等级及虚拟物品数据信息等（但用户的姓名、身份证号、电话号码等个人身份数据信息除外），属于无限觉醒产品的一部分，由无限觉醒所有并进行管理，用户有权在遵守用户协议以及产品使用规则的前提下通过正当的途径对属于自身用户账号的数据信息进行修改、转移、抛弃等。'
      ]
    },
    {
      'title': '六、免责声明及惩罚措施',
      'content': [
        `1. 用户明确同意其使用无限觉醒服务所存在的风险将完全由其自己承担；因其使用无限觉醒服务而产生的一切后果也由其自己承担，无限觉醒对用户不承担任何责任。`,
        `2. 无限觉醒对以下事宜不作任何类型的担保，不论是明示的还是默示的：`,
        `(1)本协议项下无限觉醒提供的相关服务将符合用户的要求；`,
        '(2)本协议项下无限觉醒提供的相关服务不受干扰、及时提供、安全可靠或不会出错；',
        '(3)本协议项下无限觉醒提供的相关服务无限觉醒将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任；',
        '(4)本协议项下无限觉醒提供的相关服务将不受不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机、电信部门原因及其他任何网络、技术、通信线路等外界或人为因素的影响；',
        '(5)本协议项下无限觉醒不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由无限觉醒所实际控制的任何网页上的内容，无限觉醒不承担任何责任。',

        '3. 无限觉醒保留判定用户的行为是否符合本协议约定的权利，如果用户违背了本协议的约定，无限觉醒有权依据本协议中断对用户提供的服务，并有权对用户注册的所有无限觉醒通行证账号采取包括但不限于以下一项或几项措施:',
        '(1)警告：警告仅仅是针对违反协议约定的用户做出的教育导向，它是用于正常管理无限觉醒运行的一种方式；',
        '(2)禁言：关闭违规用户的部分或全部聊天，强制暂停用户账号的对话、上传功能，使用户账号无法与其他用户对话，直到此次处罚到期或是取消；',
        '(3)暂时冻结：将违规用户的服务内容进行限制，限制其局部服务功能，直到此次处罚到期或是取消；',
        '(4)永久冻结：将违规用户的账号进行限制，限制其部分服务功能，或永久限制；',
        '(5)暂时禁止登录：违规用户的用户账号将在一定的时间内暂时无法登录使用无限觉醒服务的一个或部分平台，直到此次处罚到期或是取消；',
        '(6)永久禁止登录：违规用户的用户账号将永久无法登录使用无限觉醒服务；',
        '(7)封停账号：暂停或永久停止违规用户账号登录无限觉醒服务的权利；',
        '(8)承担法律责任：您的不当行为对他人或者无限觉醒造成损害或者与现行法律规定相违背的，您要依法承担相应的民事、行政或刑事责任；',
        '(9)其他处罚措施：包括但不限于没收不正当利益（如您通过不正当途径获得的平台虚拟物品等）、倒扣数值（包括但不限于经验值等）等。',
      ]
    },
    {
      'title': '七、服务变更、中断、中止或终止',
      'content': [
        `1. 如因网站、平台、系统、应用软件、服务维护或升级的需要而需暂停服务，无限觉醒将尽可能事先进行通告。`,
        `2. 用户在接受无限觉醒相关服务时实施不正当行为的，无限觉醒有权变更、中断、中止或终止对用户提供服务，该不当行为包括但不限于违约、违法、违反公序良俗、侵犯无限觉醒或任何第三方之权益等。`,
        '3. 用户在接受无限觉醒相关服务时实施本协议中有明确约定或属于无限觉醒事先明确告知的应被终止服务的禁止性行为的，无限觉醒有权终止对用户提供服务。',
        '4. 用户在注册账号时，提供虚假注册身份信息，无限觉醒有权终止对用户提供服务。',
        '5. 用户利用无限觉醒服务进行任何洗钱、预付卡套现、购买和销售或以其他方式非法取得虚拟货币等违法行为，无限觉醒有权在不事先通知用户的情况下中止或终止部分或全部服务的权利。',
      ]
    },
    {
      'title': '八、损害赔偿',
      'content': [
        `1. 用户同意无害地使用无限觉醒网站和无限觉醒产品、程序、账号系统及服务，避免无限觉醒因下述行为或相关行为遭受来自第三方的任何投诉、诉讼、损失、损害、责任、成本和费用（包括但不限于律师费及其他费用）：您使用无限觉醒网站和无限觉醒产品、程序、账号系统及服务的行为；您的用户内容；您违反本协议的行为。`,
        `2. 用户内容是指用户下载、发布或以其他方式使用无限觉醒网站与无限觉醒产品、程序、账号系统及服务时产生的所有内容（例如：您的信息、图片、音乐或其他内容）；您是您的用户内容唯一的责任人，您将承担因您的用户内容披露而导致的您或任何第三方被识别的风险。`,
        `3. 用户同意保障和维护无限觉醒及其他第三方的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给无限觉醒或任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任，该等责任包括但不限于给无限觉醒或其他第三方造成的一切直接和间接损失。`,
        '4. 因用户违反本协议约定之任意内容时，包括但不限于无限觉醒通过内部的监测程序发现或经其他用户举报而发现用户有可能或已出现违约、违法、违反公序良俗、侵犯任何一方权益时，则无限觉醒有权采取如下措施：包括但不限于限制用户账号的登录、限制用户在无限觉醒产品中的活动和要求用户赔偿因用户从事上述行为而给无限觉醒造成的一切直接和间接损失（包括但不限于人力成本损失、运营成本损失、商誉损失、维权损失等）、单方面解除本协议，用户在此明确同意承担上述违约责任。',
      ]
    },
    {
      'title': '九、通知',
      'content': [
        `1. 本协议可由无限觉醒随时更新，更新后的协议条款一旦公布即代替原来的协议条款，无限觉醒不再另行通知，用户可在本网站查阅最新版协议条款，敬请用户定期查询有关内容。`,
        `2. 在无限觉醒修改本协议相关条款之后，如果用户不接受修改后的条款，请立即停止使用无限觉醒提供的服务，用户继续使用无限觉醒提供的服务将被视为已接受了修改后的协议。`,
        `3. 无限觉醒所有发给用户的通知可通过无限觉醒产品内、官方网站或官方微信公众号重要页面的公告、电子邮件或固定电话、手机、QQ、微信、短信等形式传送。同时，为使您及时、全面了解无限觉醒提供的各项服务，您在此同意无限觉醒可以向您的电子邮箱、手机发送商业性短信及商业性信息。
                `,
      ]

    },
    {
      'title': '十、用户信息使用',
      'content': [
        `1. 用户在此同意并授权无限觉醒为履行本协议之目的收集您的用户信息，这些信息包括您在系统中注册的信息、您账号下的数据以及其他您在使用无限觉醒服务的过程中向无限觉醒提供或无限觉醒基于安全、用户体验优化等考虑而需收集的信息，无限觉醒对您的用户信息的使用将遵循本协议及相关法律的规定。`,
        `2. 用户在此同意无限觉醒及/或其合作方可以根据用户的用户信息，通过短信、电话、邮件等各种方式向您提供关于产品或服务的活动信息、推广信息、商业信息等各类信息。`,
      ]

    },
    {
      'title': '十一、资费政策',
      'content': [
        `1. 本公司有权决定本软件及本游戏服务的收费方式和资费标准，本公司有权就本软件和本游戏服务在不同阶段确定不同的收费方式和资费标准，且本公司有权根据需要对资费政策随时进行调整。`,
        `具体收费方式、资费标准，包括但不限于本游戏软件的收费标准、软件中虚拟物品的使用权以及增值服务（如有）的具体收费标准等，均由本公司在APP STORE或其他APP发布渠道、游戏内道具商城、道具购买界面、官网等模块上，以网络行业通用的形式颁布。`,
        `用户有义务在选择前仔细阅读，一旦用户选择下载收费的本游戏软件、购买相关虚拟物品使用权或者接受相关服务，则表明用户已经充分理解并接受相应的收费方式和资费标准。`,
        `2、本公司有权根据需要，在软件中设立、添加、修改增值服务并发行增值服务代用币。`,
        `增值服务代用币通过使用人民币兑换获得，人民币一经兑换成增值服务代用币，则视为人民币已经使用，增值服务代用币不可兑换成人民币；增值服务代用币用于购买相关虚拟道具使用权或者接受相关增值服务。`,
        `在以用户购买虚拟物品的使用权或者以接受其他增值服务作为收费项目的情况下，如超出该虚拟物品的有效使用期限或增值服务的有效服务期限（不管用户是否已经实际使用或者享受服务），或者超出有效使用或服务次数，本公司即有权采取措施取消用户对上述虚拟物品的使用权或者停止提供增值服务。`,
      ]

    },
    {
      'title': '十二、其他规定',
      'content': [
        `1. 为避免争议，本协议涉及到的有关数据、有关技术方面的问题，用户同意以无限觉醒服务器所储存的数据作为判断标准。无限觉醒保证该数据的真实性。`,
        `2. 本协议所有条款的标题仅为方便阅读，本身并无实际涵义，不能作为本协议及相关条款涵义解释的依据。`,
        `3. 如本协议中的任何条款因任何原因被判定为完全或部分无效或不具有执行力的，本协议的其他条款仍应有效并且有执行力。`,
        `4. 无限觉醒不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响无限觉醒在将来行使该权利。`,
        `5. 无限觉醒保留对本协议拥有修改，增补，删除，更新等权利。`,
        '6. 用户根据本协议获得的用户账号和密码登录无限觉醒网站或接受其他无限觉醒产品、程序及服务时，应严格遵守该网站或相关服务、产品的相关服务协议及使用守则，包括但不限于《无限觉醒服务协议》；用户登录上述网站或接受上述服务、产品时即视为对相关服务协议及使用守则的接受。',
      ]

    },
    {
      'content': [
        '青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。'
      ]
    },
    {
      'title': '十三、联系方式',
      'content': [
        '如果您对本协议或无限觉醒服务有任何意见或建议，可与无限觉醒客服联系，我们会给予您必要的帮助。',
        '如果有任何问题请您发送至以下联系方式：',
        '客服QQ：485729307',
        '联系邮箱：485729307@qq.com',
        `官方交流群: 220714787 `
      ]
    },
    {
      'content': [
        '更新日期：2022年10月31日',
        '生效日期：2022年10月31日'
      ]
    }
  ],
};

export { agreeData };
